import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the external URL
    window.location.href = 'https://pk10.fr';
  }, [navigate]);

  return null; // No need to render anything as we are redirecting
};

export default Home;