import React, { useEffect } from 'react';

const NotFound: React.FC = () => {
  useEffect(() => {
    // Redirect to the external URL for non-existing pages
    window.location.href = 'https://pk10.fr';
  }, []);

  return null; // No need to render anything since we are redirecting
};

export default NotFound;
