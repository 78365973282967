import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const DownloadAndRedirect: React.FC = () => {
  const { folderName, fileName } = useParams<{ folderName: string; fileName: string }>();

  useEffect(() => {
    if (!folderName || !fileName) return;

    // Construct the file URL (e.g., "/test/aaa.zip")
    const fileUrl = `/${folderName}/${fileName}.zip`;

    // Create a temporary link element for downloading the file
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `${fileName}.zip`;
    document.body.appendChild(link);
    link.click(); // Trigger the download
    document.body.removeChild(link);

    // Redirect to another page (e.g., Google)
    window.location.href = 'https://pk10.fr/projets'; // Redirect after the download

  }, [folderName, fileName]);

  return null;
};

export default DownloadAndRedirect;
